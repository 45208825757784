import React from 'react';
import logo from './images/KaiBot_full.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" style={{maxHeight: 200}} />
      </header>
    </div>
  );
}

export default App;
